import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
const AuthPage = lazy(() => import('../pages/AuthPage/AuthPage'))
const LogoutPage = lazy(() => import('../pages/LogoutPage/LogoutPage'))
const CreateAccountPage = lazy(() => import('../pages/CreateAccountPage/CreateAccountPage'))
const ServiceChoicePage = lazy(() => import('../pages/ServiceChoicePage/ServiceChoicePage'))
const ForgetPasswordPage = lazy(() => import('../pages/ForgetPasswordPage/ForgetPasswordPage'))
const NoMatchPage = lazy(() => import('../pages/NoMatchPage/NoMatchPage'))

const Routes = () => (
    <Suspense fallback={<div>Carregando...</div>}>
        <Switch>
            <Route exact path='/' component={AuthPage} />
            <Route exact path='/login' component={AuthPage} />
            <Route exact path='/logout' component={LogoutPage} />
            <Route exact path='/createAccount' component={CreateAccountPage} />
            <Route exact path='/serviceChoice' component={ServiceChoicePage} />
            <Route exact path='/forgetPassword' component={ForgetPasswordPage} />
            <Route exact path='*' component={NoMatchPage} />
        </Switch>
    </Suspense>
)

export { Routes }
