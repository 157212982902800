const initialState = {
    isLoad: false,
}

export const Types = {
    ENABLE_LOAD: 'session/ENABLE_LOAD',
    DISABLE_LOAD: 'session/DISABLE_LOAD',
}

export function enableLoad() {
    return {
        type: Types.ENABLE_LOAD,
        payload: {
            isLoad: true,
        }
    }
}

export function disableLoad() {
    return {
        type: Types.DISABLE_LOAD,
        payload: {
            isLoad: false,
        }
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.ENABLE_LOAD:
            return {
                ...state,
                isLoad: action.payload.isLoad
            }
        case Types.DISABLE_LOAD:
            return {
                ...state,
                isLoad: action.payload.isLoad
            }
        default:
            return state
    }
}