import React from 'react'
import { Routes } from '../../routes/routes'
// import { PageLoad } from '../PageLoad/PageLoad'
import { Container } from '../Container/Container'
import { ConnectedRouter } from 'connected-react-router'
import './style.css'

const AppRender = ({ props }) => (
    <div className="height-100">
        {/* <PageLoad /> */}
        <Container>
            <ConnectedRouter history={props.history}>
                <Routes />
            </ConnectedRouter>
        </Container>
    </div>
)

export { AppRender }