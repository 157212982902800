import React from "react"
import "./style.css"

const Container = (props) => (
    <div>
        <div className="columns height-100" >
            <div className="column">
                {props.children}
            </div>
        </div >
    </div>
)

export { Container }
